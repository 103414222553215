import React, { FC, PropsWithChildren } from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

const createApolloClient = (uri: string) => {
  const httpLink = createHttpLink({ uri });

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });
};

const client = createApolloClient(process.env.REACT_APP_GATEWAY_URI);

export const GraphQLProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
