import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { CircularProgress, makeStyles, Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import useQueryParams from "../../hooks/useQueryParams";
import Header from "./components/Header";
import OrderSignatureForm from "./OrderSignatureForm";

import { getDateStringFromDate } from "../../utils/date.helper";
import { PAYER_SIGNATURE_BY_TOKEN } from "../../graphql/queries/signature";

const useStyle = makeStyles(() => ({
  layout: {
    padding: 24,
  },
  paper: {
    marginTop: 32,
    padding: "24px",
  },
  documentWrapper: {
    margin: "20px 0"
  },
  loading: {
    display: "block",
    margin: "20vh auto",
  },
  description: {
    marginBottom: 10,
  }
}));

const OrderSignaturePage: FC = () => {
  const queryParams = useQueryParams();
  const classes = useStyle();
  const { data, loading, error } = useQuery(PAYER_SIGNATURE_BY_TOKEN, { variables: { token: queryParams.get("token") }, fetchPolicy: "no-cache" });
  const orderIdentifier = data?.payerSignature?.order?.identifier; 
  const orderName = orderIdentifier ? `N°${orderIdentifier}` : ""; 
  
  return (
    <>
      <Header>
        <Typography variant="h4">Signature de votre bon de commande</Typography>
      </Header>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          {loading ? (
            <CircularProgress className={classes.loading} size={52} thickness={4} /> 
          ) : (
            <>
              <Typography variant="h4">
                {`Visualisation de votre bon de commande ${orderName}`}
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Consultez le bon de commande et veuillez vérifier toutes les informations avant de procéder à sa signature.
              </Typography>
              <div className={classes.documentWrapper}>
                {error && (
                  <Alert icon={false} severity="error">
                    Votre bdc a été modifié et n’est plus accessible. Veuillez contacter votre commercial.
                  </Alert>
                )}
                {data && data.payerSignature.signedAt && (
                  <Alert icon={false} severity="warning">
                    <AlertTitle><strong>Ce bon de commande est déjà signé.</strong></AlertTitle>
                    <Typography>
                      {`Vous avez signé le bon de commande le ${getDateStringFromDate(data.payerSignature.signedAt)}.`}
                    </Typography>
                  </Alert>
                )}
                {data && <OrderSignatureForm data={data.payerSignature} />}
              </div>
            </>
          )}
        </Paper>
      </div>
    </>
  );
};

export default OrderSignaturePage;
