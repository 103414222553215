import React, { FC } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  header: {
    textTransform: "uppercase",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#2C6842",
    alignItems: "center",
    padding: "10px 0px"
  },
}));

const HeaderSignature: FC<any> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {children}
    </div>
  )
};

export default HeaderSignature;
