import React, { useState, FC } from "react";
import { useMutation } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab/";
import { Done } from "@material-ui/icons";

import { Field, Form } from 'react-final-form'
import DocumentPreview from "./components/DocumentPreview";
import { SIGN_ORDER } from "../../graphql/mutations/order";

const useStyle = makeStyles(() => ({
  footer: {
    display: "flex",
    flexDirection: "column"
  },
  submitButton: {
    width: 360,
    margin: "32px 0",
    alignSelf: "end"
  }
}));

const OrderSignatureForm: FC<any> = ({ data }) => {
  const classes = useStyle();
  const [signOrder, { data: response }] = useMutation(SIGN_ORDER);
  const [error, setError] = useState(false);
  const onSubmit = async () => {
    try {
      await signOrder({ variables: { token: data.token }})
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ confirm: false, signedAt: data.signedAt }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert icon={false} severity="error">
              <AlertTitle><strong>Une erreur est intervenue.</strong></AlertTitle>
              Une erreur est intervenue lors de la signature de votre bon de commande.
            </Alert>
          )}
          <DocumentPreview url={data.documentUrl} />
          {!data.signedAt && (
            <div className={classes.footer}>
              <Field type="checkbox" name="confirm">
                {props => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={props.input.checked}
                        onChange={props.input.onChange}
                        name={props.input.name}
                      />
                    )}
                    label={`En cochant cette case, j'accepte et je reconnais avoir pris connaissance du bon de commande n°${data.order.identifier}`}
                  />
                )}
              </Field>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!values.confirm || response}
                className={classes.submitButton}
              >
                Signer ce bon de commande
              </Button>
            </div>
          )}
          {error && (
            <Alert icon={false} severity="error">
              <AlertTitle><strong>Une erreur est intervenue.</strong></AlertTitle>
              <Typography>Une erreur est intervenue lors de la signature de votre bon de commande.</Typography>
            </Alert>
          )}
          {response && (
            <Alert icon={<Done />}severity="success">
              <Typography>Merci d'avoir signé le bon de commande.</Typography>
            </Alert>
          )}
        </form>
      )}
    />
  );
};

export default OrderSignatureForm;
